import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import fourToFivePercentRiskImage from "../../../resources/images/risks/4-5_percent_risk.png"
import twoToThreePercentRiskImage from "../../../resources/images/risks/2-3_percent_risk.png"

const CancerRiskAfterMastectomyAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Cancer Risk After Mastectomy</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                After a mastectomy, the <span className="weight-600 color-primary-blue">risk of the breast cancer</span> returning in the <span className="weight-600 color-primary-blue">treated breast and
                chest wall area</span> over the next 10 years is about <span className="weight-600 color-text-teal">4 to 5%</span>. This means that, on average,
                about <span className="weight-600 color-text-teal">4 to 5 of every 100 young adults</span> who choose this treatment for their breast cancer will
                have their breast cancer come back in their treated breast and chest wall area.
            </p>
            <img
                alt="Illustration of 4 to 5 of every 100 young adults"
                className="img-fluid mx-auto"
                src={ fourToFivePercentRiskImage }
            />
            <hr />

            <p>
                After a mastectomy, the <span className="weight-600 color-primary-blue">risk of breast cancer</span> developing in the <span className="weight-600 color-primary-blue">unaffected (other) breast</span> in the next 10 years is about <span className="weight-600 color-text-teal">2 to 3%</span>. This means that, on average, about <span className="weight-600 color-text-teal">2 to 3 of every 100 young adults</span> who choose this treatment for their breast cancer will
                develop cancer in their other breast.
            </p>

            <img
                alt="Illustration of 2 to 3 of every 100 young adults"
                className="img-fluid mx-auto margin-bottom-14"
                src={ twoToThreePercentRiskImage }
            />

            <p className="color-primary-blue">
                <strong>
                    This 2 to 3% risk of breast cancer developing in the other breast is the <span className="weight-600 color-text-teal">same</span> whether a woman has a
                    lumpectomy or mastectomy.
                </strong>
            </p>

            <p>
                The risk of cancer coming back in <span className="weight-600 color-primary-blue">another part of the body</span> is the <span className="weight-600 color-text-teal">same</span> whether you have a lumpectomy or
                a mastectomy.
            </p>

            <hr />

            <h4 className="color-primary-blue">Genetics and cancer risk</h4>
            <p>
                There are genetic changes (mutations) that may increase your risk of developing a second breast cancer
                in the future. If you test positive for one of these mutations, your lifetime risk of developing a new
                breast cancer can be much higher compared to someone who tests negative for a mutation. This risk will
                depend on the type of mutation you have. If you test positive for one of these mutations, your doctor
                or a genetic counselor will discuss these risks with you and help you decide what treatment is right
                for you.
            </p>
        </AccordionItemBody>
    </AccordionItem>
)

export default CancerRiskAfterMastectomyAccordionItem
