import React from "react"

import PageNavigator from "../../components/PageNavigator"
import TreatmentOptionsTable from "../../components/TreatmentOptionsTable"

const TreatmentComparisonPage = ({ navigation }) => {
    return (
        <section className="__comparison__">
            <h1>Treatment Options</h1>

            <TreatmentOptionsTable />

            <PageNavigator navigation={ navigation } />
        </section>
    )
}

export default TreatmentComparisonPage
