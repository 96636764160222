import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

const RadiationAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Radiation</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                Mastectomy does not eliminate the risk of cancer recurring in the remaining tissues of the chest wall
                or lymph nodes. In some cases, young adults who have a mastectomy also have a course of <span className="weight-600 color-primary-blue">radiation treatment</span> to
                decrease the risk of cancer returning in the treated breast and chest wall area. Radiation treatment is
                given to the treated breast area and sometimes to the lymph node regions nearby.
            </p>
            <p>
                The radiation treatment usually involves 5 <span className="weight-600 color-primary-blue">clinical visits</span> each week for 3 to 6 weeks. Each treatment
                itself is about 10 to 20 minutes. You will not feel anything when you are getting radiation treatment.
                After the treatment session is over, you can continue your regular activities. It is safe to be around
                other people after this treatment, including children.
            </p>
            <p>
                Most patients tolerate radiation treatment well and experience few <span className="weight-600 color-primary-blue">side effects</span>, but side effects are
                possible.
            </p>
            <ul>
                <li>
                    Common side effects include sunburn to the chest, irritation or itchiness of the chest skin from
                    the radiation, and fatigue.
                </li>
                <li>
                    Most side effects from radiation treatment are short term.
                </li>
                <li>
                    Radiation may impact the healing and cosmesis of reconstruction. Please talk to your surgeons for
                    more information about your reconstruction options after mastectomy.
                </li>
            </ul>
        </AccordionItemBody>
    </AccordionItem>
)

export default RadiationAccordionItem
