import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import onePercentImage from "../../../resources/images/risks/1_percent_risk.png"

const CancerRiskAfterBilateralMastectomyAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Cancer Risk After Bilateral Mastectomy</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                The <span className="weight-600 color-primary-blue">risk of cancer</span> coming back in the <span className="weight-600 color-primary-blue">treated breast and chest wall area</span> is the <span className="weight-600 color-text-teal">same</span> whether you have a unilateral or
                bilateral mastectomy.
            </p>

            <p>
                After bilateral mastectomy, the <span className="weight-600 color-primary-blue">risk of a new cancer</span> developing on the <span className="weight-600 color-primary-blue">unaffected opposite side</span> over the next 10 years is <span className="weight-600 color-text-teal">&lt;1%</span>.  This means that, on average, about <span className="weight-600 color-text-teal">1 of every 100 young adults</span> who choose this treatment for their breast cancer will develop a new breast cancer on the other side.
            </p>

            <img
                alt="1 of every 100 young adults"
                className="img-fluid mx-auto"
                src={ onePercentImage }
            />

            <hr />

            <h4 className="color-primary-blue">Genetics and cancer risk</h4>
            <p>
                There are genetic changes (mutations) that may increase your risk of developing a second breast cancer
                in the future. If you test positive for one of these mutations, your lifetime risk of developing a new
                breast cancer can be much higher compared to someone who tests negative for a mutation. This risk will
                depend on the type of mutation you have. If you test positive for one of these mutations, your doctor
                or a genetic counselor will discuss these risks with you and help you decide what treatment is right
                for you.
            </p>
        </AccordionItemBody>
    </AccordionItem>
)

export default CancerRiskAfterBilateralMastectomyAccordionItem
