import React  from "react"

import { numArray, toInt } from "../helpers/utilities.js"

import "./NumberScale.css"

const NumberScale = ({
    content,
    onScaleSelect,
    responseMessage,
    savedValue,
    scale,
    value
}) => {
    const responseMessageTriggerValue = 6
    const [selectedValue, setSelectedValue] = React.useState(toInt(savedValue))

    const handleClick = (e) => {
        const valueClicked = e.target.dataset.scalevalue
        onScaleSelect(value, valueClicked)
        setSelectedValue(toInt(valueClicked))
    }

    return (
        <div className="__number_scale__">
            {
                content !== "" && (
                    <h3>{ content }</h3>
                )
            }
            <div className="clearfix">
                <p className="float-left scale-label">Not at all important</p>
                <p className="float-right scale-label">Extremely important</p>
            </div>
            <div className="scale btn-group btn-group-toggle d-flex justify-content-start" data-toggle="buttons">
            {
                numArray(scale).map((level, i) => {
                    const numberValue = i + 1
                    const isActive = selectedValue === numberValue ? 'active' : ''

                    return (
                        <label
                            key={ numberValue }
                            className={ `btn btn-light btn-scale ${ isActive }` }
                            data-scalevalue={ numberValue }
                            onClick={ handleClick }
                        />
                    )
                })
            }
            </div>

            {
                responseMessage && selectedValue >= responseMessageTriggerValue && (
                    <div className="row">
                        <div className="col-12 col-md-6"></div>
                        <div className="col-12 col-md-6 pl-md-0">
                            <div className="alert alert-app-info my-3 text-dark">
                                { responseMessage }
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default NumberScale
