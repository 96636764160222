import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import pageLinks from "../../../resources/data/page_links.json"

const AppearanceAndSensationAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Appearance and sensation</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                After mastectomy, the <span className="color-primary-blue">appearance of the chest</span> area will
                depend on where the scar is placed and whether you choose reconstruction. It will also be asymmetrical
                (both sides will not look the same).
            </p>
            <p>
                The surgery will leave <span className="color-primary-blue">scars</span> on the breast skin that will
                fade over time.
            </p>
            <p>
                Because your surgeon will be removing the breast tissue right up to the skin of the breast, the sensory
                nerves are affected, and most women have permanent <span className="color-primary-blue">numbness</span> in the breast and chest wall and nipple area of the
                treated breast (from your collarbone to the top of your rib cage on the side with the tumor).
            </p>
            <p>
                Some young adults choose to have <span className="color-primary-blue">breast reconstruction</span> to
                help restore the look and feel of the breast that was removed. Reconstruction may be done at the same
                time as the mastectomy (immediate) or later (delayed). Learn more about breast reconstruction in the
                section below (under "Other procedures").
            </p>
            <p>
                Young adults who do not have breast reconstruction may choose to be fitted for a <span className="color-primary-blue">breast prosthesis</span> (or breast form made of silicone gel,
                foam, or other materials) that can be placed in a bra and worn under clothes.  <a className="color-primary-blue" href={pageLinks.danaFaber.breastProsthesesAndBras} rel="noopener noreferrer" target="_blank">Learn more about a prosthesis.</a>
            </p>
            <p>
                Some young adults don’t want additional surgery or to deal with special bras, magnets, or adhesive
                patches that hold a prosthesis in place. They choose no reconstruction and don’t wear a breast form.
            </p>
            <p>
                The decision to reconstruct or not is very personal. There is no "right way" to approach mastectomy and
                reconstruction (or lack of it). There is only the way that is <span className="color-primary-blue">best for you</span>,
                your preferences, and your healing.
            </p>
        </AccordionItemBody>
    </AccordionItem>
)

export default AppearanceAndSensationAccordionItem
