import React from 'react'
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

const TissueReconstructionAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Tissue Reconstruction</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                <span className="weight-600 color-primary-blue">Tissue reconstruction</span> uses skin and fat taken from the abdomen, back, thigh, or buttocks.
            </p>
            <ul>
                <li>
                    Tissue reconstruction requires a longer <span className="weight-600 color-primary-blue">surgery</span> and a longer <span className="weight-600 color-primary-blue">recovery</span> than implant reconstruction.
                </li>
                <li>
                    Because tissue reconstruction uses your own skin and tissue, the breast generally <span className="weight-600 color-primary-blue">looks and feels</span> more natural; however, the skin sensation is still not normal (remains numb).
                </li>
            </ul>
        </AccordionItemBody>
    </AccordionItem>
)

export default TissueReconstructionAccordionItem
