import React from 'react'
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

const RecoveryTimeAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Recovery Time</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                The <span className="weight-600 color-primary-blue">recovery</span> time for young adults who have immediate reconstruction is usually about 4 to 8 weeks, depending
                on whether reconstruction is tissue or implant based.
            </p>
            <p>
                The <span className="weight-600 color-primary-blue">advantages</span> of immediate reconstruction
                generally include a better cosmetic outcome and fewer follow-up procedures. In addition, women will
                wake up from surgery with a breast mound in place, which can help ease the emotional impact of losing a
                breast.
            </p>
            <p>
                A longer recovery may mean not being able to return to <span className="weight-600 color-primary-blue">regular activities</span>, including work, as soon as
                you could if you were having less surgery. There also might be additional limitations, such as
                restrictions to lifting and carrying, which may affect your daily routine in different ways, including
                going grocery shopping or caring for a baby or young child.
            </p>
        </AccordionItemBody>
    </AccordionItem>
)

export default RecoveryTimeAccordionItem
