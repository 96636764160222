import React from 'react'

import PageNavigator from "../../components/PageNavigator"
import QuestionScale from "../../components/QuestionScale"

const ImportancePage = ({
    navigation,
    onSaveProgress,
    savedValues
}) => (
    <section className="__importants__">
        <h1>What is Important to You?</h1>

        <p>
            Considering which of the following are most important to you can help you to make your decision about
            breast cancer surgery.
        </p>

        <p className="alert alert-app-info my-4">
            The information you enter below is for you and is not being saved to this website. Having a printed copy
            might be helpful, and on the last screen, you’ll have an option to print the information and your
            responses.
        </p>

        <h2>Please indicate on the scale below how important each statement is to you:</h2>

        <QuestionScale savedValues={ savedValues } onSaveProgress={ onSaveProgress } />

        <PageNavigator navigation={ navigation } />
    </section>
)

export default ImportancePage
