import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

const ShortAndLongTermEffectsAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Short- and long-term effects</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                There is a <span className="color-primary-blue">risk of surgical complications</span>, such as infection and wound healing problems, which is
                increased with a more extensive surgery.  Complication risks are increased with additional surgery,
                such as reconstruction.
            </p>
            <p>
                After mastectomy, some young adults report that their <span className="color-primary-blue">body image and self-image</span> are negatively affected,
                even after having breast reconstruction. Some young adults report that their sexuality is impacted in
                the year after their mastectomy.
            </p>
            <p>
                <span className="color-primary-blue">Breastfeeding</span> will no longer be possible in the treated breast after a mastectomy, but it will still be
                possible in the other (untreated) breast.
            </p>
            <p>
                After a mastectomy, you will still need a mammogram for the other breast.  <span className="color-primary-blue">Imaging</span> (e.g., mammogram,
                ultrasound) on the treated side will only be needed if there is a concern.
            </p>
        </AccordionItemBody>
    </AccordionItem>
)

export default ShortAndLongTermEffectsAccordionItem
