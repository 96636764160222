import React from "react"

import OtherTextField from "./OtherTextField"
import { exists, toInt } from "../helpers/utilities"

const MultChoiceQuest = ({
    choices,
    name,
    question,
    storeResult,
    storedOther,
    storedResponse,
    type
}) => {
    const [internalState, setInternalState] = React.useState({
        showOther: exists(storedResponse) && (
            storedResponse === "3" || storedResponse === "6" || storedResponse.includes("6")
        ),
        storedResponse: storedResponse
    })

    const getStoredCheckboxOptions = () => {
        let what_would = []
        if (exists(internalState.what_would)) {
            what_would = internalState.what_would
        } else if (exists(internalState.storedResponse) && typeof(internalState.storedResponse) === "object") {
            what_would = internalState.storedResponse
        }

        return what_would
    }

    const otherWasSelected = (e) => {
        return e.target.id.split("_").pop() === "other"
    }

    const isCheckbox = (e) => {
        return e.target.type === "checkbox"
    }

    const handleStoreResult = (e) => {
        let toStore = { ...internalState }
        if (otherWasSelected(e) && !isCheckbox(e) ) {
            toStore.showOther = !toStore.showOther
            toStore[e.target.name] = e.target.value
        } else if (e.target.type === "text") {
            toStore[e.target.name] = e.target.value
        } else if (isCheckbox(e)) {
            if (otherWasSelected(e)) {
                toStore.showOther = !toStore.showOther
                toStore.what_would_other_text = ""
            }

            const val = e.target.value
            const stored = getStoredCheckboxOptions()
            let what_would = typeof(stored) === "object" ? stored : [stored]
            if (what_would.includes(val)) {
                what_would = what_would.filter(i => i !== val)
            } else {
                what_would.push(val)
            }

            toStore.what_would = what_would
        } else {
            if (e.target.name === "what_treatment") {
                toStore.what_treatment_other_text = ""
            }
            toStore.showOther = false
            toStore[e.target.name] = e.target.value
        }

        setInternalState(toStore)
        storeResult(toStore, choices.length)
    }

    const getDefaultChecked = (i) => {
        const resp = internalState.storedResponse
        if (typeof(resp) === "object") {
            return resp.includes(i.toString())
        }

        return toInt(resp) === i
    }

    return (
        <div className="mult-choice-quest">
            <p>{question}</p>
            <div className="form-check">
                {
                    choices.map((choice, i) => {
                        return (
                            <div key={i}>
                                <input
                                    className="form-check-input"
                                    type={type}
                                    name={name}
                                    id={choice === "Other" ? name + "_other" : name + "_" + i}
                                    value={i}
                                    onChange={ handleStoreResult }
                                    defaultChecked={ getDefaultChecked(i) }
                                />
                                <label className="form-check-label" htmlFor={i}>
                                    {choice}
                                </label>
                            </div>
                        )
                    })
                }
            </div>
            {
                internalState.showOther && <OtherTextField
                    display={ internalState.showOther }
                    id={ `${name}_other_text` }
                    name={ `${name}_other_text` }
                    onChange={ handleStoreResult }
                    storedValue={ storedOther }
                />
            }
        </div>
    )
}

export default MultChoiceQuest
