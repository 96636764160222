import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

const RecoveryTimeAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Recovery time</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                While <span className="color-primary-blue">recovery</span> from a mastectomy is different for everyone, in general, young adults who have a mastectomy:
            </p>
            <ul>
                <li>
                    Often <span className="color-primary-blue">stay</span> overnight at the hospital following their surgery, but may be able to go home the same day.  Those receiving tissue-based reconstruction will have a longer stay.
                </li>
                <li>
                    Can expect to have temporary <span className="color-primary-blue">surgical drains</span> (tubes with a bag attached to capture extra fluid from the
                    surgical site) placed during their surgery to drain extra fluid. The surgeon
                    will take the drains out in about 1 to 2 weeks.
                </li>
                <li>
                    Can usually get back to their <span className="color-primary-blue">regular activities</span> in about 2 to 3 weeks, unless choosing to have
                    reconstruction at the time of the mastectomy, which can prolong the recovery time. Some activities,
                    like exercise, may take longer to get back to.
                </li>
            </ul>
        </AccordionItemBody>
    </AccordionItem>
)

export default RecoveryTimeAccordionItem
