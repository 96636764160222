import React from 'react'

import FollowupQuestions from "../../components/FollowupQuestions"
import PageNavigator from "../../components/PageNavigator"

const NextStepsPage = ({
    navigation,
    onSaveProgress,
    savedValues
}) => (
    <section className="__next_steps__">
        <h1>Next steps</h1>
        <p className="alert alert-app-info">
            The information you enter below is for you and is not being saved to this website. Having a printed copy
            might be helpful, and on the last screen, you’ll have an option to print the information and your
            responses.
        </p>

        <FollowupQuestions
            savedValues={ savedValues }
            onSelection={ onSaveProgress }
        />

        <PageNavigator navigation={ navigation } />
    </section>
)

export default NextStepsPage
