import React from 'react'

import NumberScale from "./NumberScale"

const QuestionScale = ({
    className,
    savedValues,
    onSaveProgress
}) => {
    const handleScaleChange = (value, scale) => {
        onSaveProgress({
            values: {
                ...savedValues,
                [value]: scale
            }
        })
    }

    const contentArray = [
        {
            stateKey: "question_1",
            leadText: "I want to avoid the possible need for additional cancer surgery (such as if the surgeon does not get an area around the tumor that is clear of cancer cells, called a \"clear margin\").",
            responseBody: (
                <div>
                    <p>Your response indicates that avoiding the possible need for additional cancer surgery is important to you.</p>
                    <p>If you choose <span className="color-primary-blue">lumpectomy</span>, additional surgery may be needed if the surgeon does not get a clear margin.</p>
                    <p>If you choose <span className="color-primary-blue">mastectomy</span>, while there is no need for additional cancer surgery to get clear margins, you may need multiple procedures if you choose reconstruction.</p>
                </div>
            )
        },
        {
            stateKey: "question_2",
            leadText: "I'd like to keep as much of my natural breast(s) as possible.",
            responseBody: (
                <div>
                    <p>Your response indicates that keeping as much of your natural breast as possible is important to you.</p>
                    <p>Choosing <span className="color-primary-blue">lumpectomy</span> will let you keep as much of your natural (treated) breast as possible vs. if you choose mastectomy.</p>
                    <p>Choosing <span className="color-primary-blue">unilateral mastectomy</span> (vs. bilateral mastectomy) will mean you keep your other (unaffected) natural breast.</p>
                </div>
            )
        },
        {
            stateKey: "question_3",
            leadText: "I want to minimize recovery time from surgery and return to my daily activities as quickly as possible.",
            responseBody: (
                <div>
                    <p>Your response indicates that minimizing recovery time and returning to your daily activities as quickly as possible is important to you.</p>
                    <p>Because it is the least extensive surgery, <span className="color-primary-blue">lumpectomy</span> has the shortest recovery time and will allow you to return to your activities as quickly as possible.</p>
                </div>
            )
        },
        {
            stateKey: "question_4",
            leadText: "I want the ability to breastfeed in the future.",
            responseBody: (
                <div>
                    <p>Your response indicates that being able to breastfeed in the future is important to you.</p>
                    <p>If you choose <span className="color-primary-blue">lumpectomy</span>, breastfeeding is possible.</p>
                    <p>If you choose <span className="color-primary-blue">unilateral mastectomy</span>, you can breastfeed in the untreated breast; breastfeeding is not possible if you choose <span className="color-primary-blue">bilateral mastectomy</span>.</p>
                </div>
            )
        },
        {
            stateKey: "question_5",
            leadText: "I want to maintain sensation in my breast.",
            responseBody: (
                <div>
                    <p>Your response indicates that maintaining sensation in your breast is important to you.</p>
                    <p>By choosing <span className="color-primary-blue">lumpectomy</span>, you will be able to maintain sensation in your treated breast.</p>
                    <p>Choosing <span className="color-primary-blue">unilateral mastectomy</span> will mean having permanent numbness in the treated breast and chest wall area.</p>
                    <p>Choosing <span className="color-primary-blue">bilateral mastectomy</span> will mean having permanent numbness in the entire breast and chest wall area.</p>
                </div>
            )
        },
        {
            stateKey: "question_6",
            leadText: "I would like to minimize the need for follow-up breast imaging (mammograms).",
            responseBody: (
                <div>
                    <p>Your response indicates that minimizing the need for mammograms is important to you.</p>
                    <p>Choosing <span className="color-primary-blue">lumpectomy</span> or <span className="color-primary-blue">unilateral mastectomy</span> will mean you still need mammograms.</p>
                    <p>If you choose <span className="color-primary-blue">bilateral mastectomy</span>, you will not need mammograms.</p>
                </div>
            )
        },
        {
            stateKey: "question_7",
            leadText: "I'd like both breasts to look as similar as possible.",
            responseBody: (
                <div>
                    <p>Your response indicates that having your breasts look as similar as possible is important to you.</p>
                    <p>Women who choose <span className="color-primary-blue">lumpectomy</span> usually have a good cosmetic outcome, including having both breasts look similar (symmetrical).</p>
                    <p>For women who have a <span className="color-primary-blue">unilateral mastectomy</span>, without reconstruction, breasts will not look similar.  Reconstruction can help achieve symmetry by restoring the shape of the breast.</p>
                    <p>For women who choose <span className="color-primary-blue">bilateral mastectomy</span>, the breast area will look similar.</p>
                </div>
            )
        },
    ]

    return (
        <div className={ className }>
        {
            contentArray.map((entry, idx) => (
                <NumberScale
                    key={ idx }
                    content={ entry.leadText }
                    onScaleSelect={ handleScaleChange }
                    responseMessage={ entry.responseBody }
                    savedValue={ savedValues[entry.stateKey] }
                    scale={ 10 }
                    value={ entry.stateKey }
                />
            ))
        }
        </div>
    )
}

export default QuestionScale
