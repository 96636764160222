import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import BilateralMastectomyAccordion from "../BilateralMastectomyAccordion";

const BilateralMastectomyAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Bilateral Mastectomy</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                <span className="weight-600 color-primary-blue">Bilateral (double) mastectomy</span> is a surgical procedure in which both breasts are removed (the breast
                with the tumor and the other breast).
            </p>
            <BilateralMastectomyAccordion />
        </AccordionItemBody>
    </AccordionItem>
)

export default BilateralMastectomyAccordionItem
