import React from 'react'
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

const TwoStageOrSingleStageImplantReconstructionAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Two-stage or Single-stage Implant Reconstruction</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                Both two-stage and single-stage implant reconstruction place a silicone or saline
                (salt water) <span className="weight-600 color-primary-blue">implant</span> in the breast area, either behind or in front of the muscle on the chest wall. Some young adults are
                candidates for an immediate implant (single stage), and some patients are not and require an expansion
                process (two stage):
            </p>
            <ul>
                <li>
                    A temporary <span className="weight-600 color-primary-blue">tissue expander</span> may be placed at the time of your mastectomy to allow the skin to recover in
                    preparation for the final breast reconstruction.
                </li>
                <li>
                    Every few weeks, saline will be injected into the expander to stretch this area.
                </li>
                <li>
                    Once the expander reaches the desired size, your plastic surgeon will replace it with permanent
                    implants.
                </li>
            </ul>
        </AccordionItemBody>
    </AccordionItem>
)

export default TwoStageOrSingleStageImplantReconstructionAccordionItem
