import React from 'react'

import MultChoiceQuest from "./MultChoiceQuest"
import { exists, toInt } from "../helpers/utilities"

import "./FollowupQuestions.css"

const FollowupQuestions = ({
    savedValues,
    onSelection
}) => {
    const handleSelection = (data) => {
        delete data.storedResponse

        // Reset Other field
        if (toInt(data.what_treatment) < 3 && exists(data.what_treatment_other_text)) {
            data.what_treatment_other_text = ""
        } else if (exists(data.what_would) && !data.what_would.includes("6") && exists(data.what_would_other_text)) {
            data.what_would_other_text = ""
        }

        onSelection({
            next: {
                ...savedValues,
                ...data
            }
        })
    }

    return (
        <div className="__followup_questions__">
            <MultChoiceQuest
                choices={ [
                    "Lumpectomy",
                    "Mastectomy",
                    "I don't know",
                    "Other"
                ] }
                name={ "what_treatment" }
                question={ "What treatment are you leaning toward?" }
                storeResult={ handleSelection }
                storedOther={ savedValues.what_treatment_other_text }
                storedResponse={ savedValues.what_treatment }
                type="radio"
            />

            <MultChoiceQuest
                choices={ [
                    <>I am <strong>ready</strong> to make a decision</>,
                    <>I am <strong>not ready</strong> to make a decision</>,
                    <>I <strong>don’t know</strong> if I’m ready to make a decision</>
                ] }
                name={ "how_ready" }
                question={ "How ready are you feeling to make a decision?" }
                storeResult={ handleSelection }
                storedOther={ savedValues.what_treatment_other_text }
                storedResponse={ savedValues.how_ready }
                type="radio"
            />

            {
                exists(savedValues.how_ready) && toInt(savedValues.how_ready) >= 1 && (
                    <MultChoiceQuest
                        choices={ [
                            "Discussing my options with my loved ones",
                            "Discussing my options with my oncologist/surgeon, nurses, or other providers",
                            "Reading more about my options",
                            "Thinking more about my options",
                            "Hearing more about other women’s decisions",
                            "I don't know",
                            "Other"
                        ] }
                        name={ "what_would" }
                        question={ "What would help you to make a decision? (Check all that apply)" }
                        storeResult={ handleSelection }
                        storedOther={ savedValues.what_would_other_text }
                        storedResponse={ savedValues.what_would }
                        type="checkbox"
                    />
                )
            }
        </div>
    )
}

export default FollowupQuestions
