import React from 'react'
import { NavLink } from "react-router-dom"

import ChevronDoubleDownIcon from "../../../icons/bootstrap/ChevronDoubleDownIcon"
import ChevronDoubleUpIcon from "../../../icons/bootstrap/ChevronDoubleUpIcon"
import DropdownNav from "./DropdownNav"
import useScreenMode, { screenModes } from "../../../hooks/useScreenMode"
import { __ } from "../../../services/i18n"

import "./LearnMorePageNavigation.css"

const LearnMorePageNavigation = () => {
    const screenMode = useScreenMode()
    const [isMobile, setIsMobile] = React.useState(screenMode <= screenModes.sm)

    // Switch to mobile mode when screen size is small
    React.useEffect(() => {
        setIsMobile(screenMode <= screenModes.sm)
    }, [screenMode])

    const className = isMobile ? 'mobile-mode' : 'navbar navbar-expand-md navbar-dark bg-custom static-top'
    const items = (
        <>
            <NavLink className="nav-link" to="/learn/treatment-options">{__('sidenav:options')}</NavLink>
            <NavLink className="nav-link" to="/learn/treatment-comparison">{__('sidenav:comparison')}</NavLink>
            <NavLink className="nav-link" to="/learn/values-clarification">{__('sidenav:important')}</NavLink>
            <NavLink className="nav-link" to="/learn/considerations">{__('sidenav:considerations')}</NavLink>
            <NavLink className="nav-link" to="/learn/next-steps">{__('sidenav:next')}</NavLink>
            <NavLink className="nav-link" to="/learn/summary">{__('sidenav:summary')}</NavLink>
        </>
    )

    return (
        <nav className={`__learn_more_page_navigation__ ${className}`} role="navigation">
            {
                isMobile && (
                    <DropdownNav
                        buttonElementWhenNotShown={ <ChevronDoubleDownIcon /> }
                        buttonElementWhenShown={ <ChevronDoubleUpIcon /> }
                    >
                        <div className="items">
                            { items }
                        </div>
                    </DropdownNav>
                ) || (
                    <div className="navbar-nav mx-auto">
                        { items }
                    </div>
                )
            }
        </nav>
    )
}

export default LearnMorePageNavigation
