import React from "react"

import { CurrentYear } from "../../services/DateService"

import pageLinks from "../../resources/data/page_links.json"

import "./Footer.css"

const Footer = () => (
    <footer className="__footer__ no-print">
        <p className="text-center">
            Copyright { CurrentYear }&nbsp;|&nbsp;
            <a href={ pageLinks.wcm.privacyPolicy } rel="noopener noreferrer" target="_blank">Privacy Policy</a>&nbsp;|&nbsp;
            <a href={ pageLinks.wcm.legalStatement } rel="noopener noreferrer" target="_blank">Legal Statements</a>
        </p>
        <p className="text-center">
            Developed by&nbsp;
            <a href={ pageLinks.danaFaber.developers } rel="noopener noreferrer" target="_blank">Dana-Faber Health Communication Core</a> and&nbsp;
            <a href={ pageLinks.wcm.developers } rel="noopener noreferrer" target="_blank">Weill Cornell Medicine Information Technology and Services</a>
        </p>
    </footer>
)

export default Footer
