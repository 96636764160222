import React from 'react'
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

const LymphNodeSurgeryAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Lymph node surgery </h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                A type of lymph node surgery called <span className="weight-600 color-primary-blue">sentinel lymph node biopsy (SLNB)</span> is generally done as part of a
                lumpectomy or mastectomy for Stages 1, 2, and 3 breast cancer. It isn’t generally done for Stage 0
                (ductal carcinoma in situ or DCIS) breast cancer.
            </p>
            <p>
                Lymph nodes are often removed during surgery to determine if cancer has spread beyond the breast. The
                first one or two lymph nodes that a tumor drains into are called <span className="weight-600 color-primary-blue">sentinel lymph nodes</span>.
            </p>
            <p>
                In an SLNB, the surgeon injects a blue dye or radioactive substance (or both) into the breast to
                identify the sentinel lymph node(s). The nodes that are stained by the dye or substance with a small
                amount of radiation (which helps the surgeon find the cancer during surgery using a handheld radiation-
                detection device) are then removed and checked for cancer cells. Depending on the results, additional
                lymph node surgery may be needed.
            </p>
            <p>
                Some women may develop <span className="weight-600 color-primary-blue">lymphedema</span> (arm swelling) after lymph node surgery or radiation.
            </p>
        </AccordionItemBody>
    </AccordionItem>
)

export default LymphNodeSurgeryAccordionItem
