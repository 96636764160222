import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

const RecoveryTimeAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Recovery Time</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                The <span className="weight-600 color-primary-blue">recovery</span> time from a bilateral mastectomy can vary from person to person, but because it is a more
                extensive surgery than a unilateral mastectomy, recovery is longer for a bilateral mastectomy. In
                general, young adults who have a bilateral mastectomy:
            </p>
            <ul>
                <li>
                    Often <span className="weight-600 color-primary-blue">stay</span> overnight at the hospital following their surgery, but may be able to go home the same day.  Those receiving tissue-based reconstruction will have a longer stay.
                </li>
                <li>
                    Can expect to have temporary <span className="weight-600 color-primary-blue">surgical drains</span> (tubes with a bag attached to capture extra fluid from the
                    surgical site) placed during their surgery to drain extra fluid. The surgeon
                    will take the drains out in about 1 to 2 weeks.
                </li>
                <li>
                    Can usually get back to their <span className="weight-600 color-primary-blue">regular activities</span> in about 4 to 6 weeks, unless choosing to have
                    reconstruction at the time of the mastectomy, which can prolong the recovery time. Some activities,
                    like exercise, may take longer to get back to.
                </li>
            </ul>
        </AccordionItemBody>
    </AccordionItem>
)

export default RecoveryTimeAccordionItem
