import i18next from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector'

// Informs i18next where to find the JSON lanaguage files
const resourceLoader = (language, namespace) => import(`./../../locales/${language}/${namespace}.json`)

/**
 * Initializes the i18next runtime with all the language specific text that the application will be using.
 *
 * @param onSuccess The runtime environment that needs to be able to utilize the i18n text
 * @param onError What to do if an error occurs while attempting to run the service
 */
export const initialize = (onSuccess, onError) => {
    i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .use(resourcesToBackend(resourceLoader))
        .init({
            debug: false,
            defaultNS: 'common',
            fallbackLng: 'en',
            ns: [
                'about',
                'common',
                'home',
                'navigation',
                'navigator',
                'procedure_lumpectomy',
                'procedure_mastectomy',
                'procedure_other',
                'resources',
                'sidenav',
                'to_consyder',
                'treatment_options',
                'treatment_table',
            ],
        })
        .then(() => {
            onSuccess()
        })
        .catch((e) => {
            if (onError !== undefined && typeof onError === 'function') {
                onError(e);
            } else {
                console.error(e);
            }
        })
}

/**
 * Allows for a lookup against the translations files.
 *
 * @param key Namespace:key to determine the resource to lookup
 * @param opts Any object mapping that can be used to inject variables into the translated text.
 *
 * @returns string
 */
export const __ = (key, opts = undefined) => {
    return opts ? i18next.t(key, opts) : i18next.t(key)
}
