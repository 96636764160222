import React, { useState } from 'react'

import { __ } from "../services/i18n"

import bilateralMastectomyImage from "../resources/images/treatments/bilateral_mastectomy_option.png"
import lumpectomyImage from "../resources/images/treatments/lumpectomy_option.png"
import mastectomyImage from "../resources/images/treatments/mastectomy_option.png"

import "./TreatmentOptionsTable.css"

const TreatmentOptionsTable = () => {
    const [show_bilateral, toggleBilateral] = useState(false)

    const handleShowBilateralSection = (event) => {
        event.preventDefault()
        toggleBilateral(!show_bilateral)
    }

    return (
        <div className="__treatment_table__">
            <div className="table-responsive">
                <table className="table table-borderless">
                    <thead>
                        <tr>
                            <th className="border-0 "></th>
                            <th className="border-0 procedure-heading text-center">
                                { __('treatment_table:lumpectomy_heading') }
                            </th>
                            <th className="border-0 procedure-heading text-center">
                                { __('treatment_table:mastectomy_heading') }
                            </th>
                            <th className={`border-0 procedure-heading text-center ${show_bilateral ? "" : "d-none"}`}>
                                { __('treatment_table:bilateral_heading') }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th></th>
                            <td>
                                <img alt="" src={ lumpectomyImage } className="img-fluid d-block mx-auto" />
                            </td>
                            <td className="procedure-column">
                                <img alt="" src={ mastectomyImage } className="img-fluid d-block mx-auto" />
                            </td>
                            <td className={`procedure-column ${show_bilateral ? "" : "d-none"}`}>
                                <img alt="" src={ bilateralMastectomyImage } className="img-fluid d-block mx-auto" />
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_1_heading') }
                            </th>
                            <td>
                                <p>{ __('treatment_table:category_1_lumpectomy_paragraph_1') }</p>
                            </td>
                            <td>
                                <p>{ __('treatment_table:category_1_mastectomy_paragraph_1') }</p>
                                <p>
                                    <button className="color-primary-blue border-0 bg-white p-0" onClick={ handleShowBilateralSection }>
                                        { __('treatment_table:category_1_mastectomy_bilateral_trigger') }
                                    </button>
                                    { __('treatment_table:category_1_mastectomy_paragraph_2') }
                                </p>
                            </td>
                            <td className={`${show_bilateral ? "" : "d-none"}`}>
                                <p>{ __('treatment_table:category_1_bilateral_paragraph_1') }</p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_2_heading') }
                            </th>
                            <td>
                                <p>{ __('treatment_table:category_2_lumpectomy_paragraph_1') }</p>
                                <p>{ __('treatment_table:category_2_lumpectomy_paragraph_2') }</p>
                                <p>{ __('treatment_table:category_2_lumpectomy_paragraph_3') }</p>
                            </td>
                            <td>
                                <p>{ __('treatment_table:category_2_mastectomy_paragraph_1') }</p>
                                <p>{ __('treatment_table:category_2_mastectomy_paragraph_2') }</p>
                                <p>{ __('treatment_table:category_2_mastectomy_paragraph_3') }</p>
                            </td>
                            <td className={`${show_bilateral ? "" : "d-none"}`}>
                                <p>{ __('treatment_table:category_2_bilateral_paragraph_1') }</p>
                                <p>{ __('treatment_table:category_2_bilateral_paragraph_2') }</p>
                                <p>{ __('treatment_table:category_2_bilateral_paragraph_3') }</p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_3_heading') }
                            </th>
                            <td>
                                <p>Chance of complications:  <span className="weight-600 color-primary-blue">Lower</span></p>
                                <p>If there aren't "clear margins," may need additional cancer surgery</p>
                            </td>
                            <td>
                                <p>Chance of complications: <span className="weight-600 color-primary-blue">Increased, especially when choosing reconstruction</span></p>
                                <p>No need for additional cancer surgery but may need multiple procedures if choosing reconstruction</p>
                            </td>
                            <td className={`${show_bilateral ? "" : "d-none"}`}>
                                <p>Chance of complications: <span className="weight-600 color-primary-blue">Further increased, especially when choosing reconstruction</span></p>
                                <p>No need for additional cancer surgery but may need multiple procedures if choosing reconstruction</p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_4_heading') }
                            </th>
                            <td>
                                <p className="mb-0">Back home: </p>
                                <p className="weight-600 color-primary-blue">Same day</p>

                                <p className="mb-0">Back to regular activities: </p>
                                <p className="weight-600 color-primary-blue">1-2 weeks</p>

                                <p className="mb-0">Drains: </p>
                                <p className="weight-600 color-primary-blue">None</p>

                                <p className="mb-0">Radiation after surgery? </p>
                                <p className="weight-600 color-primary-blue">Needed</p>
                            </td>
                            <td>
                                <p className="mb-0">Back home:</p>
                                <p className="weight-600 color-primary-blue">Same day or day after surgery*</p>

                                <p className="mb-0">Back to regular activities:</p>
                                <p className="weight-600 color-primary-blue">2-3 weeks*</p>

                                <p className="mb-0">Drains: </p>
                                <p className="weight-600 color-primary-blue">1-2 weeks</p>

                                <p className="mb-0">Radiation after surgery?</p>
                                <p className="weight-600 color-primary-blue">May or may not be needed</p>

                                <p className="clause color-primary-blue"><em>*longer with reconstruction</em></p>
                            </td>
                            <td className={`${show_bilateral ? "" : "d-none"}`}>
                                <p className="mb-0">Back home: </p>
                                <p className="weight-600 color-primary-blue">Same day or day after surgery*</p>

                                <p className="mb-0">Back to regular activities:</p>
                                <p className="weight-600 color-primary-blue">4-6 weeks*</p>

                                <p className="mb-0">Drains: </p>
                                <p className="weight-600 color-primary-blue">1-2 weeks</p>

                                <p className="mb-0">Radiation after surgery?</p>
                                <p className="weight-600 color-primary-blue"> May or may not be needed</p>

                                <p className="clause color-primary-blue"><em>*longer with reconstruction</em></p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_5_heading') }
                            </th>
                            <td>
                                <p>Risk of cancer returning <span className="weight-600 color-primary-blue">in treated breast/chest wall:</span></p>
                                <p className="statistic">3-8%</p>
                                <p>Risk of cancer developing <span className="weight-600 color-primary-blue">in other breast:</span></p>
                                <p className="statistic">2-3%</p>
                                <p>The risk of cancer returning <span className="weight-600 color-primary-blue">in another part of the body is</span> <span className="weight-600 color-text-teal">the same</span> no matter which procedure is chosen</p>
                            </td>
                            <td>
                                <p>Risk of cancer returning <span className="weight-600 color-primary-blue">in treated breast/chest wall:</span></p>
                                <p className="statistic">4-5%</p>
                                <p>Risk of cancer developing in other breast:</p>
                                <p className="statistic">2-3%</p>
                                <p>The risk of cancer returning <span className="weight-600 color-primary-blue">in another part of the body is</span> <span className="weight-600 color-text-teal">the same</span> no matter which procedure is chosen</p>
                            </td>
                            <td className={show_bilateral ? "" : "d-none"}>
                                <p>Risk of cancer returning <span className="weight-600 color-primary-blue">in treated breast/chest wall:</span></p>
                                <p className="statistic">4-5%</p>
                                <p>Risk of cancer developing <span className="weight-600 color-primary-blue">in other breast:</span></p>
                                <p className="statistic">0-1%</p>
                                <p>The risk of cancer returning <span className="weight-600 color-primary-blue">in another part of the body is</span> <span className="weight-600 color-text-teal">the same</span> no matter which procedure is chosen</p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_6_heading') }
                            </th>
                            <td>
                                <p><span className="weight-600 color-primary-blue">Necessary</span> (Both breasts)</p>
                            </td>
                            <td>
                                <p><span className="weight-600 color-primary-blue">Necessary</span> (Remaining breast)</p>
                            </td>
                            <td className={show_bilateral ? "" : "d-none"}>
                                <p><span className="weight-600 color-primary-blue">Not Necessary</span></p>
                            </td>
                        </tr>
                        <tr>
                            <th className="color-primary-blue text-right">
                                { __('treatment_table:category_7_heading') }
                            </th>
                            <td>
                                <p><span className="weight-600 color-primary-blue">Unlikely</span> (Treated breast)</p>
                                <p><span className="weight-600 color-primary-blue">Possible</span> (Remaining breast)</p>
                            </td>
                            <td>
                                <p><span className="weight-600 color-primary-blue">Possible</span> (Remaining breast)</p>
                            </td>
                            <td className={ show_bilateral ? "" : "d-none" }>
                                <p><span className="weight-600 color-primary-blue">Not Possible</span></p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default TreatmentOptionsTable
