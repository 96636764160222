import React from 'react'
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

import ReconstructionAccordion from "../ReconstructionAccordion"

const ReconstructionAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Reconstruction</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                Reconstruction is a surgical procedure to restore the shape of the breast after a mastectomy.
                Reconstruction may be <span className="weight-600 color-primary-blue">immediate</span> (performed at the same time as your mastectomy) or <span className="weight-600 color-primary-blue">delayed</span> (a separate
                surgery done at a later time). Not everyone is a candidate for immediate reconstruction, so discuss
                your options with your surgeon. Your plastic surgeon will be able to help you decide which type of
                reconstruction is right for you:
            </p>
            <ReconstructionAccordion />
        </AccordionItemBody>
    </AccordionItem>
)

export default ReconstructionAccordionItem
