import React from "react"

import FollowupQuestions from "../../components/FollowupQuestions"
import PageNavigator from "../../components/PageNavigator"
import QuestionScale from "../../components/QuestionScale"
import TreatmentOptionsTable from "../../components/TreatmentOptionsTable"

const SummaryPage = ({
    followupValues,
    navigation,
    onSaveImportanceQuestion,
    onSaveFollowupQuestion,
    savedValues
}) => (
    <section>
        <h1>Summary</h1>

        <p>
            Below is a summary of information for you to review. Your responses will not be saved to this site, so
            it may be helpful to print this page.
        </p>

        <div className="no-print">
            <button
                className="btn btn-lg btn-primary hide-from-print d-block mx-auto my-4"
                onClick={ window.print }
            >
                Print this summary
            </button>
        </div>

        <div className="my-4">
            <h2>Treatment Options</h2>
            <TreatmentOptionsTable/>
        </div>

        <div className="my-4">
            <h2 className="my-4">What's Important to You?</h2>

            <QuestionScale className="my-4" savedValues={savedValues} onSaveProgress={onSaveImportanceQuestion}/>
        </div>

        <div className="my-4">
            <h2>Next Steps</h2>

            <FollowupQuestions savedValues={ followupValues } onSelection={ onSaveFollowupQuestion } />
        </div>

        <PageNavigator navigation={ navigation } />
    </section>
)

export default SummaryPage
