import React from "react"
import { AccordionItem, AccordionItemBody, AccordionItemTitle } from "react-accessible-accordion"

const ShortAndLongTermEffectsAccordionItem = () => (
    <AccordionItem>
        <AccordionItemTitle>
            <h3>Short- and Long-term Effects</h3>
        </AccordionItemTitle>
        <AccordionItemBody>
            <p>
                There is a <span className="weight-600 color-primary-blue">risk of surgical complications</span>, such as infection and wound healing problems, which is
                increased with a more extensive surgery, such as a bilateral mastectomy vs a unilateral mastectomy.
                Complication risks are increased with additional surgery, such as reconstruction. The rate of major
                complications for both types of mastectomy are overall low.
            </p>
            <p>
                Some young adults who have mastectomies report that their <span className="weight-600 color-primary-blue">body image and self-image</span> are negatively affected
                after a mastectomy, even after having breast reconstruction. Some young adults report that their sexuality is
                impacted in the years after their mastectomy.
            </p>
            <p>
                <span className="weight-600 color-primary-blue">Breastfeeding</span> will not be possible after a bilateral mastectomy.
            </p>
            <p>
                If you have a bilateral mastectomy, you will not need routine <span className="weight-600 color-primary-blue">mammograms</span> in the future.
            </p>
        </AccordionItemBody>
    </AccordionItem>
)

export default ShortAndLongTermEffectsAccordionItem
